import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Button from '../components/Button';
import LocationContext from '../lib/locationContext';
import VideoBackground, {
  VideoBgContainer,
} from '../components/VideoBackground';
import TitleComp from '../components/TitleComp';
import GlitchText from '../components/GlitchText';
import { logEvent, PAGES } from '../lib/firebase';

const Home = () => {
  return (
    <div>
      <Intro />
    </div>
  );
};

const Intro = () => {
  const { setCurrentLocation, hasVisitedHome, setHasVisitedHome } = useContext(
    LocationContext
  );
  useEffect(() => {
    setCurrentLocation(window.location.pathname);

    if (!hasVisitedHome) {
      logEvent({ page: PAGES.HOME });
    }

    setHasVisitedHome(true);
  }, []);

  const history = useHistory();

  const handleStartPlaying = () => {
    history.push('/experience');
  };

  return (
    <VideoBgContainer>
      <div
        style={{
          position: 'absolute',
          zIndex: '1',
          fontFamily: 'Work Sans',
          fontWeight: '800',
          fontStyle: 'italic',
          width: '100%',
        }}
      >
        <TitleComp />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{ width: '400px' }}
            onClick={(e) => handleStartPlaying(e)}
          >
            start
          </Button>
          {/* <Button onClick={() => history.push('/archive')}>archive</Button> */}
        </div>
      </div>
      <VideoBackground />
      <Nav>
        <NavItem>
          <GlitchText decoration="none" to="/archive" text="ARCHIVE">
            ARCHIVE
          </GlitchText>
        </NavItem>
        <NavItem>
          <GlitchText decoration="none" to="/about" text="ABOUT">
            ABOUT
          </GlitchText>
        </NavItem>
        <NavItem>
          <GlitchText decoration="none" to="/credits" text="CREDITS">
            CREDITS
          </GlitchText>
        </NavItem>
      </Nav>
    </VideoBgContainer>
  );
};

export default Home;

// const Span = styled.span`
//   opacity: 1;
//   transition: opacity 1.25s ease-out;
//   &:hover {
//     opacity: 0;
//     transition: opacity 2.5s ease-out;
//   }
// `;

const Nav = styled.nav`
  z-index: 2;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: 'Space Mono', monospace;

  @media only screen and (max-width: 480px) {
    justify-content: center;
  }
`;

const NavItem = styled.div`
  padding: 16px;
  margin-right: 16px;
  @media only screen and (max-width: 480px) {
    margin-right: 0;
  }

  p {
    padding: 16px;
    cursor: pointer;
    text-transform: uppercase;
  }
`;
