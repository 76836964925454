import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { animated } from 'react-spring';
import Button from '../components/Button';
import { GlitchText, GlitchLink } from '../components/GlitchText';
import { ACTIONS } from '../pages/Video';
import { Circle } from 'pages/About';

const PromptOverlay = ({
  fade,
  state,
  handlePositiveClick,
  handleNegativeClick,
  dispatch,
}) => {
  const [tooltip, setTooltip] = useState('');
  const [textResponse, setTextResponse] = useState('');

  const handleMouseEnter = (text) => {
    setTooltip(text);
  };

  const handleMouseLeave = () => {
    setTooltip('');
  };

  const handleTextInput = (e) => {
    setTextResponse(e.target.value);
  };

  const submitText = () => {
    dispatch({
      type: ACTIONS.ENTER_TEXT_RESPONSE,
      payload: { textResponse, question: state.prompt.question },
    });
    handlePositiveClick();
  };
  return (
    <AnimatedOverlay style={fade}>
      <GlitchText className="title" text={state.prompt.question}>
        {state.prompt.question}
      </GlitchText>
      {state.type === 'choice' ? (
        <>
          <ButtonGroup>
            <Button
              onMouseEnter={() =>
                handleMouseEnter(state.prompt.responses.negative.tooltip)
              }
              onMouseLeave={handleMouseLeave}
              onClick={handleNegativeClick}
            >
              {state.prompt.responses.negative.text}
            </Button>
            <Button
              onMouseEnter={() =>
                handleMouseEnter(state.prompt.responses.positive.tooltip)
              }
              onMouseLeave={handleMouseLeave}
              onClick={handlePositiveClick}
            >
              {state.prompt.responses.positive.text}
            </Button>
          </ButtonGroup>
          <div style={{ height: '60px' }}>
            <AnimatedText>{tooltip}</AnimatedText>
          </div>
          {state.prompt.id === 5 && (
            <div style={{ position: 'fixed', bottom: '8px' }}>
              <p
                style={{
                  maxWidth: '58ch',
                  textAlign: 'center',
                  fontSize: '0.85rem',
                }}
              >
                By choosing &lsquo;yes&lsquo;, you agree that your story may be
                shown in the public archive. Your response will be anonymous.
                Find out more about how your stories will be stored{' '}
                <GlitchLink to="privacy" target="_blank" text="here">
                  here
                </GlitchLink>
                .
              </p>
              <Circle size="16px" />
            </div>
          )}
        </>
      ) : (
        <>
          <TextArea
            style={{ width: '60%' }}
            rows="10"
            value={textResponse}
            onChange={handleTextInput}
            name="ta"
            id="ta"
          />
          <ButtonGroup>
            <Button onClick={submitText}>submit</Button>
          </ButtonGroup>
        </>
      )}
    </AnimatedOverlay>
  );
};

export default PromptOverlay;

const BlurOverlay = styled.div`
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  background: rgba(5, 5, 10, 0.9);
  z-index: 3;

  .title {
    font-size: calc(10px + 3.2vmin);
  }
  /* backdrop-filter: blur(4px); */
`;

const ButtonGroup = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
`;

const AnimatedOverlay = animated(BlurOverlay);

const rotateText = keyframes`
0% {
  opacity: 0.55;
  transform: skew(0deg, 0deg);
}
25% {
  opacity: 0.65;
  transform: skew(2deg, 2deg);
}
50% {
  opacity: 0.75;
  transform: skew(0deg, 0deg);
}
75% 
  opacity: 0.65;
  transform: skew(-2deg, -2deg);
}
100% {
  opacity: 0.55;
  transform: skew(0deg, 0deg);
}
`;

const AnimatedText = styled.h2`
  animation: ${rotateText} 8s infinite linear alternate-reverse; ;
`;

const TextArea = styled.textarea`
  background-color: black;
  resize: none;
  color: ${(props) => props.theme.colours.light};
  outline: none;
  border: 1px solid ${(props) => props.theme.colours.light};
  font-size: 1rem;
  width: 100%;
`;
