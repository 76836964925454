import React from 'react';
import styled, { keyframes } from 'styled-components';
import FullPageWrapper from './FullPageWrapper';
const Loading = () => {
  return (
    <FullPageWrapper>
      <Loader />
    </FullPageWrapper>
  );
};

export default Loading;

const pulse = keyframes`
 0% { transform: scale(1.2); opacity: 0.6;}
 50% { transform: scale(1.6); opacity: 1; }
 100% { transform: scale(1.2); opacity: 0.6; }
`;

export const Loader = styled.div`
  background-color: ${(props) => props.theme.colours.light};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  animation-name: ${pulse};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;
