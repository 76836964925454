import { logEvent } from '../lib/firebase';
import React, { useEffect, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import LocationContext from '../lib/locationContext';

export const FADE_STEPS = 100;
export const FADE_INC = 2;

const AudioPlayer = () => {
  const { currentLocation } = useContext(LocationContext);
  const showPlayer =
    currentLocation === '/' ||
    currentLocation === '/about' ||
    currentLocation === '/resources' ||
    currentLocation === '/credits' ||
    currentLocation === '/archive';
  const [playing, setPlaying] = useState(false);
  const audioRef = useRef(null);

  const handlePlaying = () => {
    const desiredFadeTimeMs = 400;
    const stepTime = desiredFadeTimeMs / (FADE_STEPS / FADE_INC);
    setPlaying(!playing);
    if (!playing && audioRef) {
      logEvent({ page: currentLocation, type: 'trigger_background_music_on' });

      audioRef.current.volume = 0.0;
      audioRef.current.play();
      let val = 0;
      const timer = setInterval(() => {
        if (val === FADE_STEPS) {
          clearInterval(timer);
        }
        audioRef.current.volume = val / FADE_STEPS;
        val += FADE_INC;
      }, stepTime);
    } else {
      let val = FADE_STEPS;
      const timer = setInterval(() => {
        if (val === 0) {
          clearInterval(timer);
          audioRef.current.pause();
        }
        audioRef.current.volume = val / FADE_STEPS;
        val -= FADE_INC;
      }, stepTime);
    }
  };

  useEffect(() => {
    if (!showPlayer && !audioRef.current.paused) {
      const stepTime = 10000 / (FADE_STEPS / 1);
      let val = FADE_STEPS;
      const timer = setInterval(() => {
        if (val === 0) {
          clearInterval(timer);
          audioRef.current.pause();
        }
        audioRef.current.volume = val / FADE_STEPS;
        val -= 1;
      }, stepTime);
    }
  }, [currentLocation]);

  return (
    <AudioContainer>
      {showPlayer && (
        <SoundButton playing={playing} setPlaying={handlePlaying} />
      )}
      <audio
        src="laac.mp3"
        playsInline
        ref={audioRef}
        onError={(e) => console.error(e)}
        loop
      />
    </AudioContainer>
  );
};

export default AudioPlayer;

const AudioContainer = styled.div`
  position: fixed;
  bottom: 0;
  margin: 32px;
`;

const SoundButton = ({ playing, setPlaying }) => {
  return (
    <IconContainer
      onClickCapture={() => setPlaying(!playing)}
      checked={playing}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#fff"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
        />
      </svg>
    </IconContainer>
  );
};

const IconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
  svg {
    width: 24px;
    height: 24px;
    opacity: ${(props) => (props.checked ? 1.0 : 0.3)};
    transition: opacity 0.3s ease;
  }
`;
