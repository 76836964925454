import React from 'react';
import styled from 'styled-components';
import { animated, useSprings } from 'react-spring';
import { getRandomInt } from '../lib/utils';
import { FadeIn } from './FullPageWrapper';
import Button from './Button';

const Container = styled.div`
  position: absolute;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.colours.dark};
  padding: 1rem;
  margin: 0;
  padding: 0;
  p {
    font-size: calc(12px + 0.5vmax);
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
`;
export const IntroSlide = ({
  buttonFunction,
  dataToDisplay,
  showButton,
  buttonText,
  animationStyle,
}) => {
  const springs = useSprings(
    dataToDisplay.length,
    dataToDisplay.map((item, i) => ({
      text: item,
      from: {
        opacity: 0,
        transform: 'translate3d(-8px, 0, 0)',
      },
      to: {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      },
      delay: i * (1400 + getRandomInt(200, 400)),
      config: {
        mass: 100,
        tension: 100,
        friction: 100,
      },
    }))
  );

  return (
    <FadingContainer style={animationStyle}>
      <div style={{ textAlign: 'left' }}>
        {springs.map((props, index) => (
          <animated.div style={props} key={index}>
            {dataToDisplay[index]}
          </animated.div>
        ))}
      </div>
      {showButton && (
        <OffsetButton
          offset={`${dataToDisplay.length + 6}s`}
          onClick={buttonFunction}
        >
          {buttonText}
        </OffsetButton>
      )}
    </FadingContainer>
  );
};

export default IntroSlide;

const FadingContainer = animated(Container);

export const OffsetButton = styled(Button)`
  animation-name: ${FadeIn};
  animation-duration: 3s;
  animation-delay: ${(props) => (props.offset ? props.offset : '12s')};
  opacity: 0;
  animation-fill-mode: forwards;
`;
