import { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import './App.css';
import theme from './styled/Theme';
import GlobalStyle from './styled/GlobalStyle';
import AudioPlayer from './components/AudioPlayer';
import LocationContext from './lib/locationContext';
import Home from './pages/Home';
import About from './pages/About';
import Video from './pages/Video';
import Responses from './pages/Responses';
import End from './pages/End';
import Resources from './pages/Resources';
import Privacy from './pages/Privacy';
import Credits from './pages/Credits';

// import Preview from './pages/Preview';
import ScrollToTop from './lib/ScrollToTop';

function App() {
  const [currentLocation, setCurrentLocation] = useState('/');
  const [hasVisitedHome, setHasVisitedHome] = useState(false);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <LocationContext.Provider
        value={{
          currentLocation,
          setCurrentLocation,
          hasVisitedHome,
          setHasVisitedHome,
        }}
      >
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/experience" component={Video} />
            <Route path="/archive" component={Responses} />
            <Route path="/end" component={End} />
            <Route path="/resources" component={Resources} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/credits" component={Credits} />
            <Route path="*" component={Home} />
          </Switch>
        </Router>
        <AudioPlayer />
      </LocationContext.Provider>
    </ThemeProvider>
  );
}

export default App;
