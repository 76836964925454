import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

const glitchAnim = keyframes`
  0% {
    clip-path: inset(22% 9% 51% 0);
    transform: skew(0.61deg);
  }
  10% {
    clip-path: inset(24% 4% 6% 0);
    transform: skew(0.04deg);
  }
  20% {
    clip-path: inset(20% 10% 66% 0);
    transform: skew(0.84deg);
  }
  30% {
    clip-path: inset(92% 5% 5% 0);
    transform: skew(0.33deg);
  }
  40% {
    clip-path: inset(90% 1% 42% 0);
    transform: skew(0.82deg);
  }
  60% {
    clip-path: inset(4% 0% 98% 0);
    transform: skew(0.05deg);
  }
  70% {
    clip-path: inset(49% 4% 16% 0);
    transform: skew(1.2deg);
  }
  80% {
    clip-path: inset(24% 5% 6% 0);
    transform: skew(0.96deg);
  }
  90% {
    clip-path: inset(6% 2% 35% 0);
    transform: skew(0.9deg);
  }
  100% {
    clip-path: inset(71% 7% 43% 0);
    transform: skew(0.16deg);
  }
`;

const glitchAnim2 = keyframes`
  0% {
    clip-path: inset(19% 7% 94% 0);
    transform: skew(0.83deg);
  }
  5% {
    clip-path: inset(25% 3% 30% 0);
    transform: skew(0.46deg);
  }
  10% {
    clip-path: inset(27% 2% 33% 0);
    transform: skew(0.21deg);
  }
  15% {
    clip-path: inset(41% 6% 35% 0);
    transform: skew(0.86deg);
  }
  20% {
    clip-path: inset(88% 10% 63% 0);
    transform: skew(0.02deg);
  }
  25% {
    clip-path: inset(96% 1% 30% 0);
    transform: skew(0.93deg);
  }
  30% {
    clip-path: inset(82% 7% 17% 0);
    transform: skew(0.24deg);
  }
  35% {
    clip-path: inset(4% 2% 25% 0);
    transform: skew(0.6deg);
  }
  40% {
    clip-path: inset(26% 8% 24% 0);
    transform: skew(0.52deg);
  }
  45% {
    clip-path: inset(80% 2% 77% 0);
    transform: skew(0.78deg);
  }
  50% {
    clip-path: inset(51% 6% 99% 0);
    transform: skew(0.31deg);
  }
  55% {
    clip-path: inset(1% 6% 30% 0);
    transform: skew(0.46deg);
  }
  60% {
    clip-path: inset(88% 8% 36% 0);
    transform: skew(0.33deg);
  }
  65% {
    clip-path: inset(92% 3% 61% 0);
    transform: skew(0.76deg);
  }
  70% {
    clip-path: inset(69% 9% 77% 0);
    transform: skew(0.44deg);
  }
  75% {
    clip-path: inset(76% 9% 82% 0);
    transform: skew(0.38deg);
  }
  80% {
    clip-path: inset(7% 4% 74% 0);
    transform: skew(0.18deg);
  }
  85% {
    clip-path: inset(66% 5% 31% 0);
    transform: skew(0.29deg);
  }
  90% {
    clip-path: inset(89% 6% 20% 0);
    transform: skew(0.87deg);
  }
  95% {
    clip-path: inset(35% 8% 89% 0);
    transform: skew(0.65deg);
  }
  100% {
    clip-path: inset(35% 8% 89% 0);
    transform: skew(0.91deg);
  }
`;

const glitchSkew = keyframes`
  0% {
    transform: skew(0deg);
  }
  10% {
    transform: skew(2deg);
  }
  20% {
    transform: skew(0deg);
  }
  30% {
    transform: skew(1deg);
  }
  40% {
    transform: skew(1deg);
  }
  50% {
    transform: skew(-2deg);
  }
  60% {
    transform: skew(-2deg);
  }
  70% {
    transform: skew(-3deg);
  }
  80% {
    transform: skew(-1deg);
  }
  90% {
    transform: skew(3deg);
  }
  100% {
    transform: skew(-2deg);
  }
`;

const upToDown = keyframes`
  from {
    transform: translate3d(0, -4px, 0)
  }
  to {
    transform: translate3d(0, 0, 0)
  }
`;

export const downToUp = keyframes`
  from {
    transform: translate3d(0, 4px, 0)
  }
  to {
    transform: translate3d(0, 0, 0)
  }
`;

export const GlitchLink = styled(Link)`
  position: relative;
  color: white;
  text-decoration: ${(props) => props.decoration};
  letter-spacing: 0.025em;
  animation: ${glitchSkew} 1s infinite linear alternate-reverse;
  transform: perspective(0);
  transition: font-weight 0.25s ease;
  :hover {
    font-weight: 700;
    &:before {
      content: '${(props) => props.text}';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 100%;
      height: 100%;
      text-shadow: 3.5px 0 ${(props) => props.theme.colours.red70};
      clip-path: rect(10px, 150px, 26px, 0);
      animation: ${glitchAnim} 0.1s infinite linear alternate-reverse;
    }

    &:after {
      content: '${(props) => props.text}';
      position: absolute;
      top: 12px;
      left: 14px;
      width: 100%;
      height: 100%;
      text-shadow: -3.5px 0 ${(props) => props.theme.colours.blue70},
        1.5px 1.5px ${(props) => props.theme.colours.red70};
      animation: ${glitchAnim2} 0.5s infinite linear alternate-reverse;
      opacity: 0;
    }
  }
`;

export default GlitchLink;

export const GlitchText = styled.h2`
  position: relative;
  color: ${(props) => props.theme.colours.light};
  text-decoration: none;
  letter-spacing: 0.15em;
  transform: perspective(0);
  animation: ${glitchSkew} 1s infinite linear alternate-reverse,
    ${upToDown} 0.6s 1 ease;
  font-size: calc(10px + 5vmin);
  text-align: center;
  max-width: 95%;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 2;

  &:before {
    content: '${(props) => props.text}';
    color: transparent;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 100%;
    height: 100%;
    text-shadow: -6px 0 ${(props) => props.theme.colours.red70};
    /* clip-path: rect(10px, 150px, 300px, 0); */
    opacity: 0.2;
    animation: ${glitchAnim} 1.2s infinite linear alternate-reverse;
    transform: perspective(0);
    z-index: -1;
  }

  &:after {
    transform: perspective(0);
    content: '${(props) => props.text}';
    position: absolute;
    color: transparent;
    /* top: 4px; */
    /* left: 12px; */
    left: 0;
    width: 100%;
    height: 100%;
    text-shadow: -2px 0 ${(props) => props.theme.colours.blue70},
      2px 0 ${(props) => props.theme.colours.red70};
    animation: ${glitchAnim2} 0.8s infinite ease alternate-reverse;
    /* opacity: 100; */
  }
`;

export const GlitchTextNoSkew = styled.h2`
  position: relative;
  color: ${(props) => props.theme.colours.light};
  text-decoration: none;
  letter-spacing: 0.15em;
  transform: perspective(0);
  font-size: calc(10px + 5vmin);
  text-align: center;
  max-width: 95%;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 2;

  &:before {
    content: '${(props) => props.text}';
    color: transparent;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 100%;
    height: 100%;
    text-shadow: -6px 0 ${(props) => props.theme.colours.red70};
    /* clip-path: rect(10px, 150px, 300px, 0); */
    opacity: 0.2;
    animation: ${glitchAnim} 1.2s infinite linear alternate-reverse;
    transform: perspective(0);
    z-index: -1;
  }

  &:after {
    transform: perspective(0);
    content: '${(props) => props.text}';
    position: absolute;
    color: transparent;
    /* top: 4px; */
    /* left: 12px; */
    left: 0;
    width: 100%;
    height: 100%;
    text-shadow: -2px 0 ${(props) => props.theme.colours.blue70},
      2px 0 ${(props) => props.theme.colours.red70};
    animation: ${glitchAnim2} 0.8s infinite ease alternate-reverse;
    /* opacity: 100; */
  }
`;
