import React, { useState } from 'react';
import styled from 'styled-components';

const VideoBackground = () => {
  const [bgOpacity, setBgOpacity] = useState(0.8);
  const handleRangeInput = (e) => {
    e.preventDefault();
    setBgOpacity(e.target.value);
  };

  return (
    <VideoBgContainer bgOpacity={bgOpacity}>
      <Video
        // height={'100%'}
        poster="./bg_preview.jpg"
        playsInline
        autoPlay
        volume={0}
        muted
        loop
      >
        <source src="../bg.webm" type="video/webm" />
        <source src="../bg.mp4" type="video/mp4" />
      </Video>
      <InputContainer>
        <Input
          id="background"
          name="background"
          min="0"
          max="1"
          value={bgOpacity}
          step="0.05"
          onChange={(e) => handleRangeInput(e)}
          type="range"
        />
        <div style={{ fontFamily: 'sans-serif' }}>{bgOpacity}</div>
      </InputContainer>
    </VideoBgContainer>
  );
};

export default VideoBackground;

const Input = styled.input``;

const InputContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 32px;
  display: none;
`;

const Video = styled.video`
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 1;
`;

export const VideoBgContainer = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => `rgba(20,20,20,${props.bgOpacity})`};
  overflow: hidden;
  display: grid;
  place-items: center;
`;
