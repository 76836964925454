import { useEffect, useContext } from 'react';
import styled from 'styled-components';

import { logEvent, PAGES } from '../lib/firebase';
import LocationContext from '../lib/locationContext';

import { NavBar, BackArrow } from './Responses';
import { GlitchText } from '../components/GlitchText';
import { FadeIn } from '../components/FullPageWrapper';

const Privacy = () => {
  const { setCurrentLocation } = useContext(LocationContext);
  useEffect(() => {
    setCurrentLocation(window.location.pathname);
    logEvent({ page: PAGES.CREDITS });
  }, []);
  return (
    <CreditsContainer>
      <NavBar>
        <BackArrow />
        <GlitchText text="Credits">Credits</GlitchText>
        <div style={{ width: '32px', height: '32px' }} />
      </NavBar>
      <CreditsContent>
        <div style={{ textAlign: 'center' }}>
          <h2>Before We Disappear</h2>
          <h3 style={{ fontSize: '1.3rem', marginBottom: '3rem' }}>
            Written and produced by Asmaa Jama
          </h3>
        </div>

        <div
          className="flex-container"
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          <CreditCol>
            <CreditBlock>
              <H3>Cast</H3>
              <p>Lead: Gouled Ahmed</p>
              <p>Voices: Relatives</p>

              <H3>Digital experience Design / Development</H3>
              <p>Joseph Horton</p>

              <H3>Cinematography / Editing / Sound design</H3>
              <p>Gebriel Balcha</p>

              <H3>Costumes / Styling</H3>
              <p>Gouled Ahmed</p>

              <H3>Art Direction</H3>
              <p>Gouled Ahmed and Asmaa Jama</p>

              <H3>Mentor / Co-Producer</H3>
              <p>Roseanna Dias</p>

              <H3>Creative consultant / Researcher</H3>
              <p>Ibrahim Hirsi</p>
            </CreditBlock>
          </CreditCol>

          <CreditCol>
            <H3>Music</H3>
            <CreditBlock>
              <p>Shoobo</p>
              <p>Awees Khamiis Cabdalla, Axmedey Cabdi Gaashaan</p>
              <p className="small">Recorded by Prof. Francesco Gannattasio</p>
              <p className="small">
                Somali archive collection (Centro Interdipartimentale Studi
                Somali – Università Roma Tre)
              </p>
            </CreditBlock>

            <CreditBlock>
              <p>Laac</p>
              <p>Amin Xaji Cusmaan</p>
            </CreditBlock>

            <CreditBlock>
              <p>Nuumbi</p>
              <p>Awees Maxamed Banaadir, Xuseen Guhaad</p>
            </CreditBlock>

            <CreditBlock>
              <p>Lasilaaf</p>
              <p>Amin Xaji Cusmaan</p>
              <p className="small">Recorded by John Low</p>
              <p className="small">(British Library collection)</p>
            </CreditBlock>

            <H3>With Thanks to</H3>
            <CreditBlock>
              <p>John Low</p>
              <p>Andrea Zarza (British Library)</p>
              <p>
                Prof. Annarita Puglielli (Centro Interdipartimentale Studi
                Somali – Università Roma Tre)
              </p>
              <p>Joseph Amoah (BBC R&D)</p>
              <p>Luke Emery (Pervasive Media Studio)</p>
            </CreditBlock>

            <H3>Special Thanks to</H3>
            <CreditBlock>
              <p>Khevyn Ibrahim</p>
              <p>Tendai John Mutambu</p>
              <p>Emybelle Huberson-Abie</p>
              <p>Indira Toussaint</p>
              <p>Fozia Ismail</p>
              <p>Ayan Cilmi</p>
              <p>Dorothée Munyaneza</p>
              <p>Danielle Brathwaite-Shirley</p>
              <p>Anisa Jama</p>
            </CreditBlock>
          </CreditCol>

          <CreditCol>
            <H3>New Creatives partners</H3>
            <CreditBlock>
              <b>Producer</b>
              <p>Katherine Stevens</p>
              <p className="small">(Kaleider Studios)</p>
              <br />
              <b>Commissioning Executive for BBC Arts</b>
              <p>Jonty Claypole</p>
              <br />
              <b>Executive Producer</b>
              <p>Jeremy Routledge</p>
              <p className="small">(Calling the Shots)</p>
            </CreditBlock>
          </CreditCol>
        </div>

        <LogoContainer>
          <img src="NC_Logo.jpeg" alt="BBC New Creatives Logo" />
        </LogoContainer>
        <p style={{ textAlign: 'center', marginBottom: '2rem' }}>
          A Calling the Shots / Kaleider production for New Creatives, supported
          by Arts Council England and BBC&nbsp;Arts.
        </p>
      </CreditsContent>
    </CreditsContainer>
  );
};

export default Privacy;

const CreditBlock = styled.div`
  margin-bottom: 1.5rem;
  p {
    margin-bottom: 0.2rem;
  }
  .small {
    font-style: italic;
  }
`;

const H3 = styled.h3`
  margin-top: 3rem;
`;

const LogoContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 400px;
  }
`;

const CreditsContainer = styled.div`
  padding: 0 min(4rem, 4vw);
  display: grid;
  min-height: 100%;
  animation-name: ${FadeIn};
  animation-duration: 2s;
  opacity: 0;
  animation-fill-mode: forwards;

  .flex-container {
    @media (max-width: 780px) {
      flex-direction: column;
    }
  }
`;

export const CreditsContent = styled.div`
  p {
    /* margin-bottom: 2rem; */
  }

  a {
    color: ${(props) => props.theme.colours.light};
    :hover {
      font-weight: 700;
    }
  }
`;

const CreditCol = styled.div`
  //min-width: 30vw;
  max-width: 28vw;
  padding: 0 1rem;
  @media (max-width: 780px) {
    max-width: 100%;
  }
`;
