import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* box-shadow: inset 0px 0px 0px 0.75px red; */

  }
  /* html {
    min-height: 100%;
    height: 100%;
  }
  #root, #root>div {
    min-height: 100%;
    height: 100%;
  } */
  html, body, #root {
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    /* height: 100vh; */
    font-family: 'Forum', serif;
    color: ${(props) => props.theme.colours.light};
    background-color: ${(props) => props.theme.colours.dark};
    p {
      font-size: 1.2rem;
      margin-bottom: 16px;
    }
    h2 {
      margin-top: 2rem;
      margin-bottom: 2rem;
      
    }
    h3 {
      margin-bottom: 1rem;
      margin-top: 0.5rem;
    }
  }
  h1 {
    font-style: normal;
    font-weight: normal;
    line-height: 0.9;
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 800;
    font-style: italic;    
    font-size: min(4rem, 10vw);
    letter-spacing: 0.07em;
  }

`;

export default GlobalStyle;
