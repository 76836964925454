import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSpring, animated, config } from 'react-spring';

import {
  COLLECTIONS,
  firestore,
  logEvent,
  PAGES,
  postToJSON,
} from '../lib/firebase';
import LocationContext from '../lib/locationContext';

import ResponseCard from '../components/ResponseCard';
import Button from '../components/Button';
import Loading from '../components/Loading';
import BackIcon from '../components/BackIcon';
import { GlitchText, GlitchLink } from '../components/GlitchText';
import { FadeIn } from '../components/FullPageWrapper';

const Responses = () => {
  const { setCurrentLocation } = useContext(LocationContext);
  useEffect(() => {
    setCurrentLocation(window.location.pathname);
    logEvent({ page: PAGES.ARCHIVE });
  }, []);
  const [data, setData] = useState([]);
  const [latestDoc, setLatestDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const fade = useSpring({
    opacity: canLoadMore ? 1 : 0,
    config: config.molasses,
    delay: 500,
  });

  const fetchResponses = async () => {
    const response = firestore
      .collection(COLLECTIONS.RESPONSES)
      .where('wantsToBeRemembered', '==', true)
      .where('published', '==', true)
      .orderBy('createdAt', 'desc')
      .limit(10);
    const result = await response.get();
    setLatestDoc(result.docs[result.docs.length - 1]);
    const posts = result.docs.map(postToJSON);
    setData(posts);
  };

  useEffect(() => {
    if (data.length > 1) setLoading(false);
  }, [data]);

  useEffect(() => {
    fetchResponses();
  }, []);

  const loadMore = async () => {
    const response = firestore
      .collection(COLLECTIONS.RESPONSES)
      .where('wantsToBeRemembered', '==', true)
      .where('published', '==', true)
      .orderBy('createdAt', 'desc')
      .startAfter(latestDoc)
      .limit(10);
    const result = await response.get();
    if (result.empty) {
      setCanLoadMore(false);
      return;
    }
    setLatestDoc(result.docs[result.docs.length - 1]);
    const posts = result.docs.map(postToJSON);
    data.concat(posts);
    setData(data.concat(posts));
  };

  if (loading) return <Loading />;
  else
    return (
      <ResponsesContainer>
        <NavBar>
          <BackArrow />
          <GlitchText text="Archive">Archive</GlitchText>
          <div style={{ width: '32px', height: '32px' }} />
        </NavBar>
        <p
          style={{
            maxWidth: '800px',
            margin: '0 auto',
            padding: '32px',
            marginBottom: '1.5rem',
          }}
        >
          Everything in the archive is from the people who were here before you.
          The ways they keep existing and their stories. This is what remains of
          them. This is what they wanted remembered.
        </p>

        {data && data.map((item) => <ResponseCard data={item} key={item.id} />)}

        <CenteredGroup>
          {canLoadMore ? (
            <Button onClick={loadMore} className="button" style={fade}>
              load more
            </Button>
          ) : (
            <animated.p style={fade}>no more responses right now</animated.p>
          )}
        </CenteredGroup>
        <p
          style={{
            maxWidth: '800px',
            margin: '0 auto',
            padding: '32px',
            marginBottom: '1.5rem',
          }}
        >
          Want to know more about how your stories will be stored?{' '}
          <GlitchLink to="privacy" target="_blank" text="Visit here">
            Visit here
          </GlitchLink>
          .
        </p>
      </ResponsesContainer>
    );
};

export default Responses;

const ResponsesContainer = styled.div`
  padding: 0 min(4rem, 4vw);
  display: grid;
  /* min-height: 100%; */
  height: auto;
  animation-name: ${FadeIn};
  animation-duration: 2s;
  opacity: 0;
  animation-fill-mode: forwards;
`;

export const NavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  font-family: 'Work Sans', sans-serif !important;
  font-style: italic;
  max-height: 140px;
`;

export const BackArrow = () => {
  const history = useHistory();
  return (
    <div
      onClick={() => history.push('/')}
      style={{
        marginRight: '16px',
        cursor: 'pointer',
      }}
    >
      <BackIcon />
    </div>
  );
};

const CenteredGroup = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
  .button {
    margin: 0 auto;
  }
  p {
    line-height: 1;
    margin: 0;
    font-size: 1rem;
  }
`;
