import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { AboutContainer, AboutContent, Circle } from './About';
import { GlitchText } from '../components/GlitchText';
import { NavBar, BackArrow } from './Responses';
import LocationContext from '../lib/locationContext';
import { logEvent, PAGES } from '../lib/firebase';

const Resources = () => {
  const { setCurrentLocation } = useContext(LocationContext);
  useEffect(() => {
    setCurrentLocation(window.location.pathname);
    logEvent({ page: PAGES.RESOURCES });
  }, []);
  return (
    <AboutContainer>
      {/* <ImageBackground src={blurBg} /> */}
      <NavBar>
        <BackArrow />
        <GlitchText text="Resources">Resources</GlitchText>
        <div style={{ width: '32px', height: '32px' }} />
      </NavBar>
      <AboutContent>
        <p>
          Exploring themes like this can bring things up for us. Here are some
          links in case you need support.
        </p>
        {/* UK Resources */}
        <Group>
          <h2>What to do if you need help now - UK</h2>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.mind.org.uk/information-support/guides-to-support-and-services/crisis-services/helplines-listening-services/"
            >
              Mental Health Support Directory
            </a>
            : who to call / emergency services
          </p>
          <p>
            <a target="_blank" rel="noreferrer" href="https://www.mind.org.uk/">
              Mind
            </a>
            : mental health support / resources
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.samaritans.org/"
            >
              Samaritans
            </a>
            : call 116 123
          </p>
          <h3>Non emergency mental health resources</h3>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/miseryparty/?hl=en"
            >
              Misery
            </a>
            : London-based mental health collective for QTIBPOC
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.baatn.org.uk/find-a-therapist/"
            >
              Black & Asian Therapist Network
            </a>
            : therapist Directory
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.otrbristol.org.uk/"
            >
              Off The Record
            </a>
            : Youth mental health services
          </p>
        </Group>

        {/* US Resources */}
        <Group>
          <h2>What to do if you need help now - USA and international</h2>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.samaritans.org/"
            >
              Samaritans
            </a>
            : email jo@samaritans.org to speak with someone about your emotional
            well being
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.thetrevorproject.org/get-help-now/"
            >
              TrevorLifeline, TrevorChat, and TrevorText
            </a>
            : helpline US 1-866-488-7386 / online chat is international
          </p>
          <h3>Non emergency mental health resources</h3>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://fireweedcollective.org/"
            >
              The Fireweed Collective
            </a>
            : US-based radical mental health collective
          </p>
          <p style={{ fontStyle: 'italic' }}>
            Is there something missing? Let us know{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:beforewedisappear.project@gmail.com"
            >
              here
            </a>
            .
          </p>
        </Group>

        <Circle />
      </AboutContent>
    </AboutContainer>
  );
};

export default Resources;

const Group = styled.div`
  margin-bottom: 4rem;
`;
