import { useEffect, useContext } from 'react';

import { logEvent, PAGES } from '../lib/firebase';
import LocationContext from '../lib/locationContext';
import { AboutContainer, AboutContent, Circle } from '../pages/About';
import { NavBar } from './Responses';
import { GlitchText } from '../components/GlitchText';

const Privacy = () => {
  const { setCurrentLocation } = useContext(LocationContext);
  useEffect(() => {
    setCurrentLocation(window.location.pathname);
    logEvent({ page: PAGES.PRIVACY });
  }, []);
  return (
    <AboutContainer>
      <NavBar style={{ justifyContent: 'center' }}>
        <GlitchText text="Privacy">Privacy</GlitchText>
      </NavBar>
      <AboutContent>
        <h3>Purpose of this policy</h3>
        <p>
          We take your privacy and the protection of your personal data
          seriously and act in accordance with all data protection legislation
          including the EU General Data Protection Regulation (GDPR). This
          privacy notice explains how personal information is collected, used,
          stored and disclosed by us when you add your text to the archive or
          leave your email address for us to get in touch with you about the
          ongoing project, Before We Disappear.
        </p>
        <h3>Who is collecting your information</h3>
        <p>
          Asmaa Jama [hereafter referred to as ‘The Artist’] is an independent
          artist who manages all personal information submitted through the
          site. The partners on this project are BBC Arts, Kaleider and Calling
          The Shots. The Artist will act as the data controller.
        </p>
        <h3>Why we’re collecting your information and how we’ll use it</h3>
        <ul>
          <li>
            We’re collecting your archive text as part of the project Before We
            Disappear.
          </li>
          <li>
            We’re using the information you provide to develop the archive for
            this project.
          </li>
          <li>
            We will not ask you for your contact details or record any personal
            data when you submit your text to the archive. All the data is
            anonymised.
          </li>
          <li>
            Your contribution to the archive may be used in future
            film/audio/interactive content related to the piece.
          </li>
          <li>
            The Artist will only store your email address for the purposes of
            letting you know about future developments of the work e.g. other
            commissions or iterations of Before We Disappear.
          </li>
          <li>The information you submit is only used for this purpose.</li>
          <li>No cookies are stored through the site.</li>
        </ul>
        <h2>How we keep your information safe</h2>
        <p>
          Ensuring your data is secure is important to us and we have restricted
          access controls.
        </p>
        <h3>Storage of your information</h3>
        <p>
          Unless you object, the information contained in your archive is kept
          for the duration of the project. Our servers are hosted within
          Mainland Europe and follow all local data protection rules.
        </p>
        <h3>Your Rights</h3>
        <p>
          You have the following rights in relation to your archive information
        </p>
        <ul>
          <li>
            Your right to request a copy of the information you have submitted
          </li>
          <li>Your right to get your information corrected or deleted</li>
          <li>Your right to limit how we use your data</li>
          <li>The right to object to the use of your data</li>
          <li>Your right to raise a concern</li>
          <li>
            You have the right to lodge a complaint with the supervisory
            authority, The Information Commissioner’s Office – www.ico.org.uk
          </li>
        </ul>
        <h3>Who to contact</h3>
        <p>
          If you have queries about how your data is being used, please contact
          The Artist via email:
        </p>
        <p>Asmaa Jama</p>
        <p>Bristol</p>
        <p>United Kingdom</p>
        <p>Email: beforewedisappear.project@gmail.com</p>
        <Circle />
      </AboutContent>
    </AboutContainer>
  );
};

export default Privacy;
