import URL from './videoUrls.json';

export const questions = [
  {
    id: 0,
    type: 'choice',
    question: 'Have you ever felt invisible?',
    responses: {
      positive: {
        text: 'Yes',
        tooltip: 'I am unseen, unwitnessed',
        nextVideo: URL.BWD_Story_2_YES_WC.prod,
        nextIncrement: 1,
      },
      negative: {
        text: 'No',
        tooltip: 'I have always felt seen',
        nextVideo: URL.BWD_Story_1_NO_WC.prod,
        shouldEarlyEnd: true,
        nextIncrement: 1,
      },
    },
  },

  {
    id: 1,
    type: 'choice',
    question: 'Have you ever wanted to disappear?',
    responses: {
      positive: {
        text: 'Yes',
        tooltip: 'I am disappearing all the time',
        nextVideo: URL.BWD_Story_3_YES_WC.prod,
        nextIncrement: 2,
      },
      negative: {
        text: 'No',
        tooltip: 'I have always wanted to be whole',
        nextVideo: URL.BWD_Story_3_NO_WC.prod,
        nextIncrement: 1,
      },
    },
  },

  {
    id: 2,
    type: 'input',
    question: 'What are your protection spells?',
    responses: {
      positive: {
        text: '',
        tooltip: '',
        nextVideo: './clip_x.mp4',
        nextIncrement: 3,
      },
      negative: {
        text: '',
        tooltip: '',
        nextVideo: '',
      },
    },
  },

  {
    id: 3,
    type: 'choice',
    question: 'Do you want to be remembered?',
    responses: {
      positive: {
        text: 'Yes',
        tooltip: 'Remember me',
        nextVideo: URL.BWD_STORY_4__YES_WC.prod,
        nextIncrement: 1,
      },
      negative: {
        text: 'No',
        tooltip: 'Forget me',
        nextVideo: URL.BWD_Story_4__NO_WC.prod,
        shouldEndNext: true,
        nextIncrement: 1,
      },
    },
  },

  {
    id: 4,
    type: 'input',
    question: 'What do you want me to remember?',
    responses: {
      positive: {
        text: '',
        tooltip: '',
        nextVideo: './clip_x.mp4',
        nextIncrement: 1,
      },
      negative: {
        text: '',
        tooltip: '',
        nextVideo: '',
      },
    },
  },

  {
    id: 5,
    type: 'choice',
    question: 'Can I hold this in the archive for others to see?',
    shouldEndNext: true,
    responses: {
      positive: {
        text: 'Yes',
        tooltip: 'Remember me',
        nextVideo: './clip_x.mp4',
        nextIncrement: 0,
        shouldEndNext: true,
      },
      negative: {
        text: 'No',
        tooltip: 'Forget me',
        nextVideo: './clip_x.mp4',
        nextIncrement: 0,
        shouldEndNext: true,
      },
    },
  },
];

export default questions;
