/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import styled, { keyframes } from 'styled-components';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

const GlitchImage = () => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push('/');
    }, 3000);
  }, []);

  return (
    <ImageContainer>
      <video
        playsInline
        muted
        autoPlay
        loop
        src="clip_x.mp4"
        style={{ minWidth: '100%', margin: '0 auto' }}
        alt="Gltched Video Of Main Character Saying Go Home"
      />
    </ImageContainer>
  );
};

export default GlitchImage;

const ImageContainer = styled.div`
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-x: hidden;
  display: flex;
`;
