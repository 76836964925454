const theme = {
  colours: {
    light: '#F2F1E6',
    dark: '#191919',
    red70: 'rgba(255, 29, 29, 0.7)',
    blue70: 'rgba(33, 240, 203, 0.7)',
  },
  fonts: { garamond: 'Cormorant Garamond, serif', mono: 'Space Mono' },
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },
};

export default theme;
