import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBzYSPXa3H83rCdZH9CEGfaW8TXnOb0xEU',
  authDomain: 'before-we-disappear.firebaseapp.com',
  projectId: 'before-we-disappear',
  storageBucket: 'before-we-disappear.appspot.com',
  messagingSenderId: '308515127168',
  appId: '1:308515127168:web:a55356a035eb9ec0cb8b65',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase
    .auth()
    .signInAnonymously()
    .then(() => {
      // Signed in..
    })
    .catch((error) => {
      const errorMessage = error.message;
      console.error(errorMessage);
      // ...
    });
}
const increment = firebase.firestore.FieldValue.increment(1);
export const time = firebase.firestore.Timestamp;
export const firestore = firebase.firestore();
export const timestamp = firebase.firestore.FieldValue.serverTimestamp();
export const fireb = firebase;
export const COLLECTIONS = {
  RESPONSES_TEST: 'responses-test',
  RESPONSES: 'responses',
  EVENTS_TEST: 'events-test',
  EVENTS: 'events',
};
export const PAGES = {
  HOME: 'home',
  ABOUT: 'about',
  ARCHIVE: 'archive',
  RESOURCES: 'resources',
  EXPERIENCE: 'experience',
  PRIVACY: 'privacy',
  CREDITS: 'credits',
  PREVIEW: 'preview',
};

export const postToJSON = (doc) => {
  const data = doc.data();
  return {
    ...data,
    createdAt: data.createdAt.toDate(),
    id: doc.id,
  };
};

export const submitResponse = (question, response, wantsToBeRemembered) => {
  if (response.length !== 0) {
    firestore.collection(COLLECTIONS.RESPONSES).add({
      published: false,
      createdAt: timestamp,
      question: question,
      response: response,
      wantsToBeRemembered: wantsToBeRemembered,
    });
  } else {
    logEvent({ page: PAGES.EXPERIENCE, type: 'submit_response' });
  }
};

export const logEvent = (data) => {
  const type = data.type ? data.type : 'page_view';
  const eventRef = firestore.collection(COLLECTIONS.EVENTS).doc();
  const statsRef = firestore.collection(COLLECTIONS.EVENTS).doc('--stats--');
  const batch = firestore.batch();
  batch.set(eventRef, {
    createdAt: timestamp,
    page: data.page,
    type,
  });
  if (type === 'page_view') {
    batch.set(
      statsRef,
      { page_views: { [data.page]: increment } },
      { merge: true }
    );
  } else {
    batch.set(statsRef, { [type]: increment }, { merge: true });
  }
  batch.commit();
};

export const logResponseEvent = (data) => {
  const type = 'question_response';
  const eventRef = firestore.collection(COLLECTIONS.EVENTS).doc();
  const statsRef = firestore.collection(COLLECTIONS.EVENTS).doc('--stats--');
  const batch = firestore.batch();
  batch.set(eventRef, {
    createdAt: timestamp,
    page: data.page,
    type,
  });

  batch.set(
    statsRef,
    { question_responses: { [data.question]: { [data.response]: increment } } },
    { merge: true }
  );

  batch.commit();
};
