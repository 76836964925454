import styled, { keyframes } from 'styled-components';

export const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FullPageWrapper = styled.div`
  width: 100vw;
  min-height: 100%;
  height: 100%;
  display: flex;
  padding: ${(props) => (props.noPadding ? '0' : '2rem')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: ${({ black }) => (black ? '#000' : 'transparent')};
  opacity: 1;
  animation-name: ${FadeIn};
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
  /* overflow: hidden; */
`;

export default FullPageWrapper;
