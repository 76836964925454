import { useHistory } from 'react-router-dom';
import FullPageWrapper from '../components/FullPageWrapper';
import Button from '../components/Button';

const End = () => {
  const history = useHistory();

  return (
    <FullPageWrapper>
      <h1>Thanks for sharing your voice</h1>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button onClick={() => history.push('/archive')}>
          view the archive
        </Button>
      </div>
    </FullPageWrapper>
  );
};

export default End;
