/* eslint-disable no-unused-vars */
import React, {
  useState,
  useRef,
  useReducer,
  useEffect,
  useContext,
} from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { useSpring } from 'react-spring';
import { useHistory } from 'react-router-dom';

import {
  logEvent,
  logResponseEvent,
  PAGES,
  submitResponse,
} from '../lib/firebase';
import LocationContext from '../lib/locationContext';

import FullPageWrapper from '../components/FullPageWrapper';
import data from '../data/questions';
import URL from '../data/videoUrls.json';
import { introPageText, endPageText } from '../data/slateStrings';
import PromptOverlay from '../components/PromptOverlay';
import VideoStatusIndicator from '../components/VideoStatusIndicator';
import RotateOverlay from '../components/RotateOverlay';
import { IntroSlide, OffsetButton } from '../components/IntroSlide';
import { GlitchText, GlitchTextNoSkew } from '../components/GlitchText';
import Button from '../components/Button';
import GlitchImage from '../components/GlitchImage';
import { FADE_INC, FADE_STEPS } from '../components/AudioPlayer';

export const ACTIONS = {
  VIDEO_COMPLETED: 'video_completed',
  CLICK_POSITIVE: 'positive',
  CLICK_NEGATIVE: 'negative',
  SET_PLAYING_STATE: 'set_playing_state',
  END_EXPERIENCE: 'end_experience',
  ENTER_TEXT_RESPONSE: 'enter_text_response',
  END_EXPERIENCE_GLITCH: 'end_experience_glitch',
};

const initialState = {
  questionId: -1,
  currentUrl: URL.BWD_Story_1_INTRO_WC.prod,
  playingState: false,
  questionVisible: false,
  prompt: data[0],
  type: data[0].type,
  shouldEnd: false,
  showEndSlate: false,
  nextIncrement: 1,
  protectionSpellsInput: '',
  questionAnswered: '',
};

const reducer = (state, action) => {
  const proposedNextId = state.questionId + state.nextIncrement;
  switch (action.type) {
    case ACTIONS.VIDEO_COMPLETED:
      if (proposedNextId < data.length) {
        return {
          ...state,
          questionVisible: true,
          questionId: proposedNextId,
          prompt: data[proposedNextId],
          type: data[proposedNextId].type,
          shouldEnd: state.shouldEndNext ? state.shouldEndNext : false,
          playingState: false,
        };
      } else return { ...state, playingState: false };
    case ACTIONS.CLICK_POSITIVE:
      logResponseEvent({
        page: PAGES.EXPERIENCE,
        question: state.prompt.question,
        response: 'yes',
      });
      return {
        ...state,
        questionVisible: false,
        currentUrl: state.prompt.responses.positive.nextVideo,
        shouldEnd: state.prompt.responses.positive.shouldEndNext ? true : false,
        nextIncrement: state.prompt.responses.positive.nextIncrement,
        playingState: true,
      };
    case ACTIONS.CLICK_NEGATIVE:
      logResponseEvent({
        page: PAGES.EXPERIENCE,
        question: state.prompt.question,
        response: 'no',
      });
      return {
        ...state,
        questionVisible: false,
        currentUrl: state.prompt.responses.negative.nextVideo,
        shouldEnd: state.prompt.responses.negative.shouldEndNext ? true : false,
        shouldEarlyEnd: state.prompt.responses.negative.shouldEarlyEnd
          ? true
          : false,
        nextIncrement: state.prompt.responses.negative.nextIncrement,
        playingState: true,
      };
    case ACTIONS.SET_PLAYING_STATE:
      return {
        ...state,
        playingState: action.payload.playing,
      };
    case ACTIONS.END_EXPERIENCE:
      return {
        ...state,
        showEndSlate: true,
      };
    case ACTIONS.ENTER_TEXT_RESPONSE:
      return {
        ...state,
        protectionSpellsInput: action.payload.textResponse,
        questionAnswered: action.payload.question,
      };
    case ACTIONS.END_EXPERIENCE_GLITCH:
      return {
        ...state,
        showGlitchEndSlate: true,
      };
    default:
      return state;
  }
};

const Video = () => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [isIntroSlide, setIsIntroSlide] = useState(true);
  const [iosVideoState, setIosVideoState] = useState({
    volume: 0,
    muted: true,
  });
  const videoRef = useRef(null);
  const noiseLoopRef = useRef(null);
  const [progressTransition, setProgressTransition] = useState('width');
  const [playerState, setPlayerState] = useState('paused');
  const [noiseLoopFadingIn, setNoiseLoopFadingIn] = useState(false);
  const [noiseLoopFadingOut, setNoiseLoopFadingOut] = useState(false);
  const { setCurrentLocation } = useContext(LocationContext);
  useEffect(() => {
    setCurrentLocation(window.location.pathname);
    logEvent({ page: PAGES.EXPERIENCE });
  }, []);

  const handleEnd = () => {
    videoRef.current && videoRef.current.seekTo(0, 'seconds');
    dispatch({ type: ACTIONS.SET_PLAYING_STATE, payload: { playing: false } });
    setPlayerMuted(true);

    if (state.shouldEarlyEnd) {
      dispatch({ type: ACTIONS.END_EXPERIENCE_GLITCH });
      if (noiseLoopRef.current) {
        noiseLoopRef.current.play();
        noiseLoopRef.current.volume = 1.0;
      }
    } else if (state.shouldEnd) {
      dispatch({ type: ACTIONS.END_EXPERIENCE });
    } else {
      dispatch({ type: ACTIONS.VIDEO_COMPLETED });
    }

    setProgressTransition('none');
    setCurrentProgress(0);
  };

  const handlePositiveClick = () => {
    dispatch({ type: ACTIONS.CLICK_POSITIVE });
    setCurrentProgress(0);
    handleVideoStartPlaying();

    if (state.questionId === 5) {
      submitResponse(state.questionAnswered, state.protectionSpellsInput, true);
    }
  };

  const handleNegativeClick = () => {
    setCurrentProgress(0);
    dispatch({ type: ACTIONS.CLICK_NEGATIVE });
    handleVideoStartPlaying();
    if (state.questionId === 5) {
      submitResponse(
        state.questionAnswered,
        state.protectionSpellsInput,
        false
      );
    }
  };

  const handleProgress = (state) => {
    setCurrentProgress(state.played * 100);
    if (state.played >= 0.99) {
      handleEnd();
    }
  };

  const fade = useSpring({
    opacity: state.questionVisible ? 1 : 0,
    pointerEvents: state.questionVisible ? 'all' : 'none',
  });

  const introSlideFade = useSpring({
    opacity: isIntroSlide ? 1 : 0,
    pointerEvents: isIntroSlide ? 'all' : 'none',
  });

  const handleVideoStartPlaying = () => {
    dispatch({ type: ACTIONS.SET_PLAYING_STATE, payload: { playing: true } });
    videoRef.current.playing = true;
    setPlayerMuted(false);
  };

  const handleStartExperience = () => {
    handleVideoStartPlaying();
    setIsIntroSlide(false);
  };

  const handleVideoStart = () => {
    setProgressTransition('width');
    setCurrentProgress(0);
    setPlayerState('playing');
  };

  const handleBuffer = () => {
    if (playerState === 'paused') {
      setPlayerState('buffering');
    }
  };

  const handleError = (e) => {
    console.error(e);
    dispatch({ type: ACTIONS.SET_PLAYING_STATE, payload: { playing: false } });
  };

  const handleReady = () => {
    setPlayerMuted(true);
    // videoRef.current.getInternalPlayer().play();
  };

  const fadeInTimerRef = useRef(null);
  const fadeOutTimerRef = useRef(null);
  useEffect(() => {
    state.playingState ? setPlayerState('playing') : setPlayerState('paused');
    if ((noiseLoopRef.current && state.questionVisible) || isIntroSlide) {
      if (noiseLoopFadingOut) {
        clearInterval(fadeOutTimerRef.current);
        setNoiseLoopFadingOut(false);
      }
      setNoiseLoopFadingIn(true);
      // -- fading in --
      const desiredFadeTimeMs = 5000;
      const stepTime = desiredFadeTimeMs / (FADE_STEPS / FADE_INC);
      if (noiseLoopRef.current) {
        noiseLoopRef.current.volume = 0.0;
        noiseLoopRef.current.play();
      }
      let val = 0;
      fadeInTimerRef.current = setInterval(() => {
        if (val === FADE_STEPS) {
          clearInterval(fadeInTimerRef.current);
          setNoiseLoopFadingIn(false);
        }
        if (noiseLoopRef.current) {
          noiseLoopRef.current.volume = val / FADE_STEPS;
        }
        val += FADE_INC;
      }, stepTime);
    } else if (noiseLoopRef.current && state.playingState) {
      // -- fading out --
      if (noiseLoopFadingIn) {
        clearInterval(fadeInTimerRef.current);
        setNoiseLoopFadingIn(false);
      }
      setNoiseLoopFadingOut(true);
      const desiredFadeTimeMs = 2000;
      const stepTime = desiredFadeTimeMs / (FADE_STEPS / FADE_INC);
      let val = FADE_STEPS;
      fadeOutTimerRef.current = setInterval(() => {
        if (val <= 1) {
          clearInterval(fadeOutTimerRef.current);
          noiseLoopRef.current.pause();
          setNoiseLoopFadingOut(false);
        }
        if (noiseLoopRef.current) {
          noiseLoopRef.current.volume = val / FADE_STEPS;
        }
        val -= FADE_INC;
      }, stepTime);
    }
  }, [state.playingState, state.questionVisible]);

  const setPlayerMuted = (muted) => {
    const volume = muted ? 0 : 1;
    setIosVideoState({
      volume: volume,
      muted: muted,
    });
  };

  // state.showGlitchEndSlate
  return state.showGlitchEndSlate ? (
    <FullPageWrapper>
      <GlitchImage />
    </FullPageWrapper>
  ) : state.showEndSlate ? (
    <>
      <IntroSlide
        buttonFunction={() => history.push('/')}
        dataToDisplay={endPageText}
        showButton={true}
        buttonText="Home"
      />
      <audio
        src="noise-loop.mp3"
        playsInline
        ref={noiseLoopRef}
        onError={(e) => console.error(e)}
        loop
        autoPlay
        style={{ visibility: 'hidden' }}
      />
    </>
  ) : (
    <FullPageWrapper noPadding black>
      <audio
        src="noise-loop.mp3"
        playsInline
        ref={noiseLoopRef}
        onError={(e) => console.error(e)}
        loop
        autoPlay
        style={{ visibility: 'hidden' }}
      />
      <ReactPlayer
        config={{
          vimeo: {
            playerOptions: {
              dnt: true,
            },
          },
        }}
        width="100%"
        style={{ minHeight: '100%' }}
        height="100%"
        ref={videoRef}
        url={state.currentUrl}
        autoPlay
        playsinline
        playing={state.playingState}
        volume={iosVideoState.volume}
        muted={iosVideoState.muted}
        loop={false}
        pip={false}
        controls={false}
        progressInterval={500}
        onStart={handleVideoStart}
        // onEnded={handleEnd}
        onError={(e) => handleError(e)}
        onProgress={(state) => handleProgress(state)}
        onBuffer={handleBuffer}
        onReady={handleReady}
      />
      <Progress transition={progressTransition} percent={currentProgress} />
      <PromptOverlay
        fade={fade}
        state={state}
        handlePositiveClick={handlePositiveClick}
        handleNegativeClick={handleNegativeClick}
        dispatch={dispatch}
      />
      <IntroSlide
        buttonFunction={handleStartExperience}
        dataToDisplay={introPageText}
        showButton={true}
        buttonText="Enter"
        animationStyle={introSlideFade}
      />
      <VideoStatusIndicator
        overlayShowing={state.questionVisible}
        currentState={playerState}
        playButtonAction={() => handleVideoStartPlaying()}
      />
      <RotateOverlay />
    </FullPageWrapper>
  );
};

export default Video;

const Progress = styled.div.attrs((props) => ({
  style: {
    width: props.percent + '%',
  },
}))`
  height: 4px;
  position: fixed;
  bottom: 0;
  left: 0;
  transition: ${(props) => props.transition} 500ms linear;
  background-color: ${(props) => props.theme.colours.light};
`;
