import React from 'react';
import styled, { keyframes } from 'styled-components';

const RotateOverlay = () => {
  return (
    <Container>
      <div className="phone" />
      <div className="message">
        <p>Please rotate your device</p>
        <p>For the optimal experience, view on a larger screen</p>
      </div>
    </Container>
  );
};

export default RotateOverlay;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
`;

const Container = styled.div`
  @media only screen and (max-width: 480px) {
    display: flex !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
  padding: 0;
  margin: 0;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colours.dark};
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1001;
  opacity: 0;
  p {
    margin-bottom: 0;
    opacity: 0;
    transition: opacity 1s linear;
    color: ${(props) => props.theme.colours.light};
    @media only screen and (max-width: 480px) {
      opacity: 1 !important;
    }
  }
  .phone {
    height: 50px;
    width: 100px;
    border: 2px solid ${(props) => props.theme.colours.light};
    border-radius: 8px;
    animation: none;
    @media only screen and (max-width: 480px) {
      animation: ${rotate} 1.5s ease-in-out infinite alternate !important;
    }
  }

  .message {
    margin-top: 40px;
    font-size: 12px;
    text-align: center;
    max-width: 48ch;
    p {
      margin-bottom: 12px;
    }
  }
`;
