// import StyledLink from '../components/StyledLink';
import GlitchLink from '../components/GlitchText';
export const introPageText = [
  <p key={0}>
    This is an interactive experience, made collaboratively across the globe.
  </p>,
  <p key={1}>
    It comes from the overlapping ways we are watched / disappeared /
    hyper-seen.
  </p>,
  <p key={2}>
    We are Black, migrant, working class, transnational. We have been tired. We
    hope it serves as a sort of balm, a sort of ancestral care.
  </p>,
  <p key={3}>
    We have been wanting rest for a long time. Let this be a space of rest. Let
    this be an archive.
  </p>,
  <p key={4}>
    &quot;All of us filling up dark bodies, are all dead, a race with more dead
    than left alive, a ghost people, haunting the world that killed us&quot; -{' '}
    <i>Safia Elhillo.</i>
  </p>,
  <p key={5}>We are what remains.</p>,
];

export const endPageText = [
  <p key={-1}>Thank you for being a part of this</p>,
  <p key={0}>
    I know this may have been exhausting for you, as someone who has not been
    seen.
  </p>,
  <p key={1}>It has been tiring for me.</p>,
  <p key={2}>
    I want you to know that you are cared for, that this space is for you.
  </p>,
  <p key={3}>
    There are other voices like yours in the{' '}
    <GlitchLink decoration="underline" text="archive" to="/archive">
      archive
    </GlitchLink>
    .
  </p>,
  <p key={4}>
    I know that this care is limited so if you need here are{' '}
    <GlitchLink decoration="underline" text="other places" to="/resources">
      other&nbsp;places
    </GlitchLink>{' '}
    that can help.
  </p>,
  <p key={5}>You are held.</p>,
  <p key={6}>You are understood.</p>,
  <p key={7}>You are felt.</p>,
];
