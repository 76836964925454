import React from 'react';

const BackIcon = () => {
  return (
    <svg
      width="29"
      height="14"
      viewBox="0 0 29 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.15468V5.84528L1 5.26793V5.20853H1.10289L10 0.0717773V5.20853H29V6.67258V7.20853V8.67258H10V13.9282L0 8.15468Z"
        fill="#F2F1E6"
      />
    </svg>
  );
};

export default BackIcon;

// const BackIcon = () => {
//   return (
//     <svg
//       width="32"
//       height="32"
//       viewBox="0 0 32 32"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M2 14.2085L11.0214 9L13.0214 12.4641L10 14.2085H30V15.6726V16.2085V17.6726H10L13.0214 19.417L11.0214 22.8811L2 17.6726V16.2085V15.6726V14.2085Z"
//         fill="#F2F1E6"
//       />
//     </svg>
//   );
// };

// export default BackIcon;
