import styled from 'styled-components';
import { lighten } from 'polished';

const Button = styled.button`
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8px);
  color: ${(props) => props.theme.colours.light};
  height: 40px;
  width: auto;
  min-width: 128px;
  font-family: ${(props) => props.theme.fonts.mono};
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.07em;
  border: none;
  outline: none;
  transition: transform 0.5s ease-out;
  transform: none;
  margin: 8px;
  padding: 0 24px;
  box-shadow: inset 0px 0px 0px 1px #b3b3b3;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  transform: translateZ(0);
  transition: background-color 0.2s cubic-bezier(0.98, -0.05, 0.89, 0.64);
  opacity: ${(props) => (props.isDisabled ? 0.4 : 1.0)};

  &:hover {
    background-color: ${(props) =>
      props.theme.colours.light && lighten(0.5, props.theme.colours.light)};
    color: ${(props) => props.theme.colours.dark};
  }
`;

export default Button;
