/* eslint-disable no-unused-vars */
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Loader } from '../components/Loading';

const VideoStatusIndicator = ({
  currentState,
  playButtonAction,
  overlayShowing,
}) => {
  const handleClick = () => {
    playButtonAction();
  };
  return (
    <Container>
      {!overlayShowing && currentState === 'paused' ? (
        <PlayButton clickFunction={handleClick} />
      ) : currentState === 'buffering' ? (
        <Buffering />
      ) : (
        <div />
      )}
    </Container>
  );
};

export default VideoStatusIndicator;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;

  min-width: 88px;
  min-height: 88px;
  display: grid;
  place-items: center;

  svg {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
`;

const PlayButton = ({ clickFunction }) => {
  return (
    <div onClick={clickFunction}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#F2F1E6"
        stroke="#F2F1E6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polygon points="5 3 19 12 5 21 5 3" />
      </svg>
    </div>
  );
};

const load = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 20px;
    opacity: 1.0;
  }
  40% {
    box-shadow: 0 -8px;
    height: 24px;
    opacity: 0.5;
  }
}
`;

const Outer = styled.div``;

const Buffering = () => (
  <Outer>
    <Loader />
  </Outer>
);
