import styled from 'styled-components';
import { downToUp } from '../components/GlitchText';

const dateOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const ResponseCard = ({ data }) => {
  return (
    <ResponseContainer>
      <li>{data.question}</li>
      <p>{'"' + data.response + '"'}</p>
      <p
        style={{
          alignSelf: 'flex-end',
          fontFamily: 'Space Mono, monospace',
          fontSize: '0.8rem',
        }}
      >
        {data.createdAt.toLocaleDateString('en-US', dateOptions)}
      </p>
    </ResponseContainer>
  );
};

export default ResponseCard;

const ResponseContainer = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  font-size: 1.2rem;
  margin: 16px;
  justify-self: center;
  font-family: 'Forum', serif;
  animation: ${downToUp} 0.6s ease-out;

  li {
    font-size: 0.8rem;
    list-style-type: square;
    font-family: 'Space Mono', monospace;
    margin-bottom: 0.5rem;
  }
`;
