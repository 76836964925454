// @refresh reset

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { GlitchText } from '../components/GlitchText';

const TitleComp = () => {
  return (
    <div>
      <Container>
        <GlitchText text="Before We Disappear">
          Before We <span style={{ opacity: 1.0 }}>Disappear</span>
        </GlitchText>
      </Container>
    </div>
  );
};

export default TitleComp;

const fade = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const Container = styled.div`
  display: grid;
  place-items: center;
  text-align: center;
  position: relative;
  width: 100%;
  height: 200px;
  h1 {
    user-select: none;
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 800;
    font-style: italic;
  }

  span {
    animation: ${fade} 10s linear 2s infinite;
    animation-direction: alternate;
  }
`;
